import React from "react"

import Layout from "../../components/layout"
import Jquery from "../../components/screens/Skill/Jquery"
import { Helmet } from "react-helmet"

const JqueryPage = () => (
  <Layout pageInfo={{ pageName: "Jquery/Ajax" }} sitePage="site-page">
    <Helmet defer={false}>
        <title>Jquery Ajax</title>
        <meta name="description" content="Jquery Ajax" />
    </Helmet>
    <Jquery />
  </Layout>
)

export default JqueryPage
